import {
    Flex,
    UseForm, SegmentedControl, icon
} from "src/components";
import React from "react";
import {InputType} from "src/enums";
import {useRts} from "src/provider";
import {HStack} from "@chakra-ui/react";
import {WordsWords} from "src/views/admin/components/PriceProposal/words.words";
import {WordsOther} from "src/views/admin/components/PriceProposal/words.other";


export function Words() {
    const {Redux} = useRts();
    const {useStore, dispatch, actions, dispatchAsync} = Redux();

    const vendors = useStore.shared.vendors
        .filter(x => Number(x.value) > -99)
        .map(x => x.value === "-1" ? {...x, label: "-> Všichni výrobci"} : x)
        .sort((a, b) => a.label.localeCompare(b.label));
    const editState = useStore.admin.PriceProposalConfig.words.data_addorupdate;
    const [separator, setSeparator] = React.useState<string>("words");
    const data = {
        ...useStore.admin.PriceProposalConfig.words.data,
        onPageSize: (e?: number) => dispatchAsync(actions.admin.fetch.GetPriceProposalDefaultText({
            page_number: 1,
            page_size: e || useStore.admin.PriceProposalConfig.words.data.page_size,
            search: `vendor.vendor_code="${editState.vendor.vendor_code}"`
        })),
        onNext: () => dispatchAsync(actions.admin.fetch.GetPriceProposalDefaultText({
            page_number: data.page_number + 1,
            page_size: data.page_size,
            search: `vendor.vendor_code="${editState.vendor.vendor_code}"`
        })),
        onPrev: () => dispatchAsync(actions.admin.fetch.GetPriceProposalDefaultText({
            page_number: data.page_number - 1,
            page_size: data.page_size,
            search: `vendor.vendor_code="${editState.vendor.vendor_code}"`
        }))
    };
    const handleRetrieve = (e: any) => {
        dispatch(actions.admin.setPriceProposalConfigWordsSelectedVendor(e[0]))
        dispatchAsync(actions.admin.fetch.GetPriceProposalDefaultText({
            page_number: data.page_number,
            page_size: data.page_size,
            search: `vendor.vendor_code="${e[0]}"`
        }));
    }

    return (
        <>
            <Flex direction={"column"} gap={2}>
                <UseForm required
                         key={"vendors"}
                         label={"Výrobce"}
                         value={(editState.vendor.vendor_code ?? "0") == "0" ? undefined : [editState.vendor.vendor_code]}
                         onChange={handleRetrieve}
                         placeholder={"-- Vyber výrobce --"}
                         collection={vendors}
                         type={InputType.select}/>

                <SegmentedControl
                    mt={4}
                    defaultValue={separator}
                    onChange={(e: any) => setSeparator(e.target.value)}
                    items={[
                        {
                            value: "words",
                            label: (
                                <HStack borderRadius={"15px"}>
                                    <icon.LuPenLine/>
                                    Věty
                                </HStack>
                            ),
                        },
                        {
                            value: "other",
                            label: (
                                <HStack>
                                    <icon.LuServerCog/>
                                    Ostatní nastavení
                                </HStack>
                            ),
                        }
                    ]}
                />


                {separator === "words" && <WordsWords/>}
                {separator === "other" && <WordsOther/>}
            </Flex>
        </>
    );
}