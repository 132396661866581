import {Avatar, Card, Flex, Text, icon} from "src/components";
import React from "react";

export default function PriceList() {
    return (<Card isHoverable={true} variant={"subtle"} size={"sm"} onClick={() => {
    }} bodyElement={<Flex
        direction={"row"}
        gap={4}
        align={"center"}
        alignItems={"center"}
        justifyContent={"space-between"}
    >
        <Avatar icon={<></>}><icon.LuEuro/></Avatar>
        <Flex direction={"column"}>
            <Text>
                Ceníky
            </Text>
            <Text fontSize={"xs"}></Text>
        </Flex>
        <Flex direction={"column"}>
            <Text fontSize={"sm"}></Text>
            <Text fontSize={"xs"}>
                Definice ceníků
            </Text>
        </Flex>
        <icon.FaArrowRight/>
    </Flex>}/>)
}