import {Button, Flex, icon} from "src/components";
import React from "react";
import {useRts} from "src/provider";

export function KubernetesHeader() {
    const {Redux} = useRts()
    const {dispatchAsync, dispatch, useStore, actions} = Redux();
    return (
        <Flex justifyContent={"center"}>
            <Button size={"xs"}
                    toolTip={"Získat autorizační token"}
                    variant={"surface"}
                    onClick={() => {
                        dispatchAsync(actions.shared.fetch.RetrieveKubernetesToken()).then((res) => {
                            dispatch(actions.core.newMessage({
                                title: "Kubernetes token",
                                serverity: "success",
                                duration: 2000,
                                message: "Token byl úspěšně získán"
                            }));
                        }).catch((err) => {
                            dispatch(actions.core.newMessage({
                                title: "Kubernetes token",
                                serverity: "error",
                                duration: 2000,
                                message: "Nepodařilo se získat aktuální token"
                            }));
                        });
                    }}>
                <icon.LuDownload/>
                Získat token
            </Button>
            {useStore.shared.kubernetes_token !== "" &&
                <Button size={"xs"}
                        toolTip={"Zkopírovat autorizační token do schránky"}
                        variant={"surface"}
                        onClick={() => {
                            navigator.clipboard.writeText(useStore.shared.kubernetes_token ?? "");
                            dispatch(actions.core.newMessage({
                                title: "Kubernetes token",
                                serverity: "success",
                                duration: 2000,
                                message: "Token byl zkopírován do schránky"
                            }));
                        }}>
                    <icon.LuClipboardCopy/>
                </Button>}
        </Flex>
    );
}