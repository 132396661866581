import {useRts} from "src/provider";

export function ServerState() {
    const {Redux, Components, useEffect} = useRts();
    const {Flex, Card, Text} = Components;
    const {useStore, dispatch, actions, dispatchAsync} = Redux();
    const data = useStore.admin.Server;
    useEffect(() => {
        dispatchAsync(actions.admin.signalR.GetServerStatus());
    }, []);
    return (
        <Flex w={"100%"} direction={"column"} gap={2}>
            <Card w={"100%"} bodyElement={
                <Flex direction={"column"} gap={2}>
                    <Text>Aktivních uživatelů</Text>
                    <Text color={"gray"}>{data.connections}</Text>
                </Flex>
            }/>
            <Card w={"100%"} bodyElement={
                <Flex direction={"column"} gap={2}>
                    <Text>Process ID</Text>
                    <Text color={"gray"}>{data.processId}</Text>
                </Flex>
            }/>
            <Card w={"100%"} bodyElement={
                <Flex direction={"column"} gap={2}>
                    <Text>Počet procesů</Text>
                    <Text color={"gray"}>{data.processorCount}</Text>
                </Flex>
            }/>
            <Card w={"100%"} bodyElement={
                <Flex direction={"column"} gap={2}>
                    <Text>Verze RTS</Text>
                    <Text color={"gray"}>{data.version}</Text>
                </Flex>
            }/>
            <Card w={"100%"} bodyElement={
                <Flex direction={"column"} gap={2}>
                    <Text>Verze Root server</Text>
                    <Text color={"gray"}>{data.versionString}</Text>
                </Flex>
            }/>
            <Card w={"100%"} bodyElement={
                <Flex direction={"column"} gap={2}>
                    <Text>Serverový čas</Text>
                    <Text color={"gray"}>{new Date(data.serverTime).toLocaleString()}</Text>
                </Flex>
            }/>
            <Card w={"100%"} bodyElement={
                <Flex direction={"column"} gap={2}>
                    <Text>Aktuální umístění</Text>
                    <Text color={"gray"}>{data.currentDirectory}</Text>
                </Flex>
            }/>
            <Card w={"100%"} bodyElement={
                <Flex direction={"column"} gap={2}>
                    <Text>Systémové umístění</Text>
                    <Text color={"gray"}>{data.systemDirectory}</Text>
                </Flex>
            }/>
            <Card w={"100%"} bodyElement={
                <Flex direction={"column"} gap={2}>
                    <Text>Aktuální vlákno</Text>
                    <Text color={"gray"}>{data.currentManagedThreadId}</Text>
                </Flex>
            }/>
            <Card w={"100%"} bodyElement={
                <Flex direction={"column"} gap={2}>
                    <Text>Název serveru</Text>
                    <Text color={"gray"}>{data.machineName}</Text>
                </Flex>
            }/>
        </Flex>
    )
}