import {useRts} from "src/provider";
import {CustomerMapAddOrUpdate} from "src/types/validate/admin/price-proposal";
import {PriceProposalCustomerMapPrice} from "src/types/rts";

export function CustomerMapItemAction() {
    const {Redux, Components, validate} = useRts();
    const {Flex, Button, icon} = Components;
    const {useStore, dispatchAsync, actions} = Redux();
    const editState = useStore.admin.PriceProposalConfig.customers.map_addorupdate;
    const validateModel = validate<PriceProposalCustomerMapPrice>({
        isValid: false,
        model: CustomerMapAddOrUpdate,
        data: editState
    });
    const handleAddOrUpdate = () => {

    }

    return <Flex direction={"column"} gap={4}>
        <Button icon={<icon.LuUpload/>}
                disabled={!validateModel.isValid}
                text={"Přidat"}
                variant={"surface"}
                onClick={handleAddOrUpdate}/>
    </Flex>
}