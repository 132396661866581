import {ButtonProps as ChakraButtonProps, Flex} from "@chakra-ui/react"
import {
    AbsoluteCenter,
    Button as ChakraButton,
    Span,
    Spinner,
} from "@chakra-ui/react"
import {forwardRef} from "react"
import {useRedux} from "src/redux/provider";
import {Tooltip} from "src/components";

interface ButtonLoadingProps {
    loading?: boolean
    loadingText?: React.ReactNode
}

export interface ButtonProps extends ChakraButtonProps, ButtonLoadingProps {
    toolTip?: string
    icon?: React.ReactNode
    text?: string
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
    function Button(props, ref) {
        const {loading, disabled, loadingText, children, toolTip, icon, text, ...rest} = props
        const {useStore} = useRedux()

        let is_loading = loading ?? useStore.core.loading;
        return (
            <Tooltip content={toolTip} disabled={!toolTip}>
                <ChakraButton disabled={is_loading || disabled} ref={ref} {...rest}>
                    {icon}
                    {text}
                    {children}
                </ChakraButton>
            </Tooltip>
        )
    },
)
