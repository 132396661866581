import {
    Button, Navigations
} from "src/components";
import React from "react";
import {WordsAddOrUpdate} from "./words.addorupdate";
import {WordsAddOrUpdateAction}
    from "./words.addorupdate.action";
import {WordsItem} from "./words.item";
import {useRts} from "src/provider";


export function WordsWords() {
    const {UseDialog, Redux} = useRts();
    const {useStore, dispatch, actions, dispatchAsync} = Redux();
    const editState = useStore.admin.PriceProposalConfig.words.data_addorupdate;
    const data = {
        ...useStore.admin.PriceProposalConfig.words.data,
        onPageSize: (e?: number) => dispatchAsync(actions.admin.fetch.GetPriceProposalDefaultText({
            page_number: 1,
            page_size: e || useStore.admin.PriceProposalConfig.words.data.page_size,
            search: `vendor.vendor_code="${editState.vendor.vendor_code}"`
        })),
        onNext: () => dispatchAsync(actions.admin.fetch.GetPriceProposalDefaultText({
            page_number: data.page_number + 1,
            page_size: data.page_size,
            search: `vendor.vendor_code="${editState.vendor.vendor_code}"`
        })),
        onPrev: () => dispatchAsync(actions.admin.fetch.GetPriceProposalDefaultText({
            page_number: data.page_number - 1,
            page_size: data.page_size,
            search: `vendor.vendor_code="${editState.vendor.vendor_code}"`
        }))
    };
    const handleAdd = () => {
        dispatch(actions.admin.setPriceProposalWordsData({
            ...editState,
            id: 0,
            text: "",
            type: "",
            photo_count: 0,
            vendor: editState.vendor
        }));
        UseDialog({
            open: true,
            title: editState.id > 0 ? "Upravit větu pro výrobce" : "Přidat větu pro výrobce",
            size: "xl",
            noPadding: false,
            bodyElement: <WordsAddOrUpdate/>,
            footerElement: <WordsAddOrUpdateAction/>,
            closable: true,
        });
    };

    return (
        <>
            <Button size={"sm"} variant={"surface"}
                    mt={4}
                    disabled={editState.vendor.vendor_code === "0" || editState.vendor.vendor_code === undefined}
                    onClick={handleAdd}>Přidat
                větu pro výrobce</Button>

            <Navigations
                mb={{mb: 2}}
                type=""
                data={data}
                renderComponent={WordsItem}
            />
        </>
    );
}