import {useRts} from "src/provider";

export function ServerActions() {
    const {Redux, Components, useEffect, UseDialog} = useRts();
    const {Flex, Button} = Components;
    const {useStore, dispatch, actions, dispatchAsync} = Redux();

    const rebootHandler = () => {
        UseDialog({
            title: "Restartovat server ?",
            footerElement: <Flex direction={"row"} gap={6}>
                <Button variant={"surface"}
                        onClick={() => {
                            dispatchAsync(actions.admin.signalR.SetServerReboot()).then(() => {
                                dispatch(actions.core.newMessage({
                                    title: "Server",
                                    serverity: "success",
                                    duration: 2000,
                                    message: "Server byl úspěšně restartován"
                                }));
                                UseDialog({open: false});
                            }).catch(() => {
                                dispatch(actions.core.newMessage({
                                    title: "Server",
                                    serverity: "error",
                                    duration: 2000,
                                    message: "Nepodařilo se restartovat server"
                                }));
                            });

                        }}>Restartovat</Button>
                <Button variant={"surface"}
                        onClick={() => UseDialog({open: false})}>Zrušit</Button>
            </Flex>,
            size: "sm",
            open: true
        })
    }

    return (
        <Flex>
            <Button variant={"surface"} onClick={rebootHandler}>Restartovat</Button>
        </Flex>
    );
}