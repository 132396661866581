import {
    UseForm, CheckboxCard
} from "src/components";
import React from "react";
import {InputType} from "src/enums";
import {useRts} from "src/provider";


export function WordsOther() {
    const {Redux} = useRts();
    const {useStore, actions, dispatchAsync} = Redux();
    const editState = useStore.admin.PriceProposalConfig.words.data_addorupdate;
    const config = useStore.admin.PriceProposalConfig.config
        .find(x => x.vendor?.vendor_code === editState.vendor.vendor_code) ?? {
        id: 0,
        is_not_use_default_text: false,
        is_add_tape: false,
        vendor: editState.vendor,
        time_to_expired_sec: 0,
        time_to_urgent_sec: 0
    };
    const data = {
        ...useStore.admin.PriceProposalConfig.words.data,
        onPageSize: (e?: number) => dispatchAsync(actions.admin.fetch.GetPriceProposalDefaultText({
            page_number: 1,
            page_size: e || useStore.admin.PriceProposalConfig.words.data.page_size,
            search: `vendor.vendor_code="${editState.vendor.vendor_code}"`
        })),
        onNext: () => dispatchAsync(actions.admin.fetch.GetPriceProposalDefaultText({
            page_number: data.page_number + 1,
            page_size: data.page_size,
            search: `vendor.vendor_code="${editState.vendor.vendor_code}"`
        })),
        onPrev: () => dispatchAsync(actions.admin.fetch.GetPriceProposalDefaultText({
            page_number: data.page_number - 1,
            page_size: data.page_size,
            search: `vendor.vendor_code="${editState.vendor.vendor_code}"`
        }))
    };

    return (
        <>
            {editState.vendor.vendor_code !== "-1" && editState.vendor.vendor_code !== "0" && editState.vendor.vendor_code !== undefined && (
                <>
                    <UseForm required
                             key={"whss"}
                             label={"Sklady"}
                             value={config.whs ?? []}
                             placeholder={"Zapiš sklad a stiskni enter"}
                             helperText={"Skladová zásoba se dohledává ve skladech výše definovaných."}
                             onChange={(e) => dispatchAsync(actions.admin.fetch.AddOrUpdateConfig({
                                 ...config,
                                 id: config.id,
                                 whs: e,
                                 is_not_use_default_text: !(config.is_not_use_default_text ?? false),
                                 is_add_tape: false,
                                 vendor: editState.vendor,
                                 time_to_expired_sec: config?.time_to_expired_sec ?? 0,
                                 time_to_urgent_sec: config?.time_to_urgent_sec ?? 0
                             }))}
                             type={InputType.tagsinput}/>
                    <CheckboxCard label={"Přidávat Tape"}
                                  checked={config.is_not_use_default_text ?? false}
                                  disabled={editState.vendor.vendor_code === "0" || editState.vendor.vendor_code === undefined}
                                  onChange={(e: any) => dispatchAsync(actions.admin.fetch.AddOrUpdateConfig({
                                      ...config,
                                      id: config.id,
                                      is_not_use_default_text: e.target.checked,
                                      vendor: editState.vendor,
                                      is_add_tape: false,
                                      time_to_expired_sec: config?.time_to_expired_sec ?? 0,
                                      time_to_urgent_sec: config?.time_to_urgent_sec ?? 0
                                  }))}
                                  description={"Pokud je toto pole zaškrtlé tak při vytváření cenové nabídky automaticky přidá položka TAPE"}/>
                    <CheckboxCard label={"Nepoužívat základní texty"}
                                  checked={config.is_not_use_default_text ?? false}
                                  disabled={editState.vendor.vendor_code === "0" || editState.vendor.vendor_code === undefined}
                                  onChange={(e: any) => dispatchAsync(actions.admin.fetch.AddOrUpdateConfig({
                                      ...config,
                                      id: config.id,
                                      is_not_use_default_text: e.target.checked,
                                      vendor: editState.vendor,
                                      is_add_tape: false,
                                      time_to_expired_sec: config?.time_to_expired_sec ?? 0,
                                      time_to_urgent_sec: config?.time_to_urgent_sec ?? 0
                                  }))}
                                  description={"Pokud je toto pole zaškrtlé tak se nenabízí základní texty v možnostech pro výběr věty pro kontrolní záznam."}/>
                </>
            )}
        </>
    );
}