import {CustomerItem} from "src/views/admin/components/PriceProposal/customer.item";
import React from "react";
import {useRts} from "src/provider";
import {CustomerAddOrUpdate} from "src/views/admin/components/PriceProposal/customer.addorupdate";
import {CustomerAddOrUpdateAction} from "src/views/admin/components/PriceProposal/customer.addorupdate.action";

export function CustomerRewriteEmail() {
    const {Redux, Components, UseDialog} = useRts();
    const {useStore, dispatch, actions, dispatchAsync} = Redux();
    const customers = useStore.admin.PriceProposalConfig.customers.lookup
        .map(x => ({value: x.value, label: x.label}))
        .sort((a, b) => a.label.localeCompare(b.label));
    const editState = useStore.admin.PriceProposalConfig.customers.data_addorupdate;
    const selected = useStore.admin.PriceProposalConfig.customers.selected;
    const data = {
        ...useStore.admin.PriceProposalConfig.customers.data,
        onPageSize: (e?: number) => dispatchAsync(actions.admin.fetch.GetPriceProposalCustomer({
            page_number: 1,
            page_size: e || useStore.admin.PriceProposalConfig.customers.data.page_size,
            search: `customer='"${editState.customer}"'`
        })),
        onNext: () => dispatchAsync(actions.admin.fetch.GetPriceProposalCustomer({
            page_number: data.page_number + 1,
            page_size: data.page_size,
            search: `CardCode='"${editState.customer}"`
        })),
        onPrev: () => dispatchAsync(actions.admin.fetch.GetPriceProposalCustomer({
            page_number: data.page_number - 1,
            page_size: data.page_size,
            search: `CardCode='"${editState.customer}"`
        }))
    };
    const {Button, Navigations} = Components;

    const handleAdd = () => {
        dispatch(actions.admin.setPriceProposalCustomerData({
            ...editState,
            id: 0,
            query: "",
            emailTo: "[]",
            emailCc: "[]",
            customer: editState.customer
        }));
        UseDialog({
            open: true,
            title: editState.id > 0 ? "Upravit podmínku pro zákazníka" : "Přidat podmínku pro zákaznka",
            size: "xl",
            noPadding: false,
            bodyElement: <CustomerAddOrUpdate/>,
            footerElement: <CustomerAddOrUpdateAction/>,
            closable: true,
        });
    };

    return (<>
        <Button size={"sm"} variant={"surface"}
                disabled={customers.length === 0 || selected.value === ""}
                onClick={handleAdd}>
            Přidat podmínku pro zákazníka
        </Button>

        <Navigations
            mb={{mb: 2}}
            type=""
            data={data}
            renderComponent={CustomerItem}
        />
    </>)
}