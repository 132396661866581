import * as z from 'zod'

export const CustomerMapAddOrUpdate = z.object({
    id: z.number({
        invalid_type_error: "ID musí být číslo",
    }).min(0, "ID musí být větší než -1").max(999999999, "ID je moc dlouhé"),
    desc: z.string({
        invalid_type_error: "Popis musí být text",
    }).min(2, "Popis musí být vyplněn").max(255, "Popis je moc dlouhý"),
    customer: z.string({
        required_error: "Zákazník musí být vybrán",
    }).min(2, "Zákazník musí být vybrán").max(255, "Zákazník je moc dlouhý"),
    type: z.array(z.string()).min(1, "Typ musí být vybrán").max(1, "Typ musí být vybrán"),
    value: z.string().min(1, "Hodnota musí být vyplněna").max(255, "Hodnota je moc dlouhá"),
    op: z.array(z.string()).min(1, "Operátor musí být vybrán").max(1, "Operátor musí být vybrán"),
    map: z.string().min(1, "Mapovací hodnota musí být vyplněna").max(255, "Mapovací hodnota je moc dlouhá"),
})