import {UseForm} from "src/components";
import {InputType} from "src/enums";
import {Flex, Span} from "@chakra-ui/react";
import {useRts} from "src/provider";

export function TemplateEditor() {
    const {Redux} = useRts();
    const {useStore, dispatch, actions} = Redux();
    const data = useStore.admin.Notification;
    return (
        <Flex direction={"row"} gap={4} justifyContent={"space-between"} w={"100%"} maxHeight={"100%"}>
            <UseForm type={InputType.textarea} rows={35} label={"Definice šablony html"} required
                     onChange={(e) => dispatch(actions.admin.setNotificationTemplate(e))}
                     value={data.template}/>
            <Flex mt={-3} maxH={"3xl"} w={"100%"} overflowY={"auto"} borderRadius={"15px"}
                  backgroundColor={"var(--surface)"}>
                <Span style={{width:"100%", height:"auto"}} dangerouslySetInnerHTML={{__html: data.template}}/>
            </Flex>
        </Flex>
    )
}