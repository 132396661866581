import {
    useState,
    useColorModeValue,
    Avatar,
    Flex,
    icon,
    Status,
    Text,
    MenuRoot,
    Button,
    MenuTrigger,
    MenuContent,
    MenuItem,
    Float
} from "../../items";

export default function NavbarAlerts() {
    const [data, setData] = useState([
        {
            aName: "TEST",
            aSrc: null,
            boldInfo: "TEST",
            info: "Testovací zpráva bkjdsgauhdi ab husahd isahjdlihsaldhsalkhd",
            time: new Date().toLocaleDateString(),
        },
    ]);
    const navbarIconItem = useColorModeValue("gray.500", "gray.500");
    const navbarIconItemText = useColorModeValue("gray.400", "gray.400");
    const notificationColor = useColorModeValue("gray.700", "white");
    const menuItem = (props: any) => {
        return (
            <>
                <Avatar
                    name={props.aName}
                    src={props.aSrc}
                    borderRadius="12px"
                    me="16px"
                />
                <Flex flexDirection="column" maxW={{sm: "250px", md: "250px"}}>
                    <Text fontSize="14px" mb="5px" color={notificationColor}>
                        <Flex direction={"row"} justify={"space-between"}>
                            <Text fontWeight="bold" fontSize="14px" as="span">
                                {props.boldInfo}
                            </Text>
                            <Flex alignItems="center" color={navbarIconItem}>
                                <icon.FaClock
                                    style={{width: "13px", height: "13px", marginRight: "10px"}}
                                />
                                <Text fontSize="xs" lineHeight="100%">
                                    {props.time}
                                </Text>
                            </Flex>
                        </Flex>
                    </Text>
                    <Text
                        fontWeight="regular"
                        color={navbarIconItemText}
                        fontSize="14px"
                        as="span"
                    >
                        {props.info}
                    </Text>
                </Flex>
            </>
        );
    };

    return (
        <MenuRoot>
            <MenuTrigger>
                <Button variant="ghost" size="sm">
                    <icon.FaBell/>
                    {data.length > 0 && (
                        <Float>
                        </Float>
                    )}
                </Button>
            </MenuTrigger>
            <MenuContent>
            </MenuContent>
        </MenuRoot>
    );
}
