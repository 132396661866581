import {useRts} from "src/provider";
import {PriceProposalCustomerMapPrice} from "src/types/rts/PriceProposalCustomerMapPrice";
import {CustomerMapAddOrUpdate} from "src/types/validate/admin/price-proposal";

const typeCollection = [
    {value: "level", label: "Level"},
    {value: "item", label: "Artikl"},
]

const opCollection = [
    {value: "=", label: "="},
    {value: "<", label: "<"},
    {value: ">", label: ">"},
    {value: "<=", label: "<="},
    {value: ">=", label: ">="},
    {value: "btw", label: "Mezi"},
]

export function CustomerMapItem() {
    const {Redux, Components, validate} = useRts();
    const {useStore, dispatch, actions} = Redux();
    const data = useStore.admin.PriceProposalConfig.customers.map_addorupdate;
    const {Flex, Input, Select} = Components;
    const validateModel = validate<PriceProposalCustomerMapPrice>({
        isValid: false,
        model: CustomerMapAddOrUpdate,
        data: data
    });

    console.log(validateModel);

    return <Flex direction={"column"} gap={4}>
        <Input fieldProps={{
            label: "Název",
            required: true,
            helperText: "Poznávací název mappingu",
            invalid: validateModel.errors?.find(x => x.name === "desc")?.isInvalid ?? false,
            errorText: validateModel.errors?.find(x => x.name === "desc")?.error
        }}
               value={data.desc}
               onChange={(e) => dispatch(actions.admin.setCustomerMapAddOrUpdate({
                   ...data,
                   desc: e.target.value
               }))}/>
        <Select fieldProps={{
            label: "Typ",
            required: true,
            helperText: "Typ mappingu (např. level práce, artikl, ...)",
            invalid: validateModel.errors?.find(x => x.name === "type")?.isInvalid,
            errorText: validateModel.errors?.find(x => x.name === "type")?.error
        }}
                value={data.type}
                collection={typeCollection}
                onValueChange={(e: any) => dispatch(actions.admin.setCustomerMapAddOrUpdate({
                    ...data,
                    type: e.value
                }))}/>
        <Input fieldProps={{
            label: "Hodnota",
            required: true,
            helperText: "Hodnota mappingu (např. 1, 2, 3, ...)",
            invalid: validateModel.errors?.find(x => x.name === "value")?.isInvalid,
            errorText: validateModel.errors?.find(x => x.name === "value")?.error
        }}
               value={data.value}
               onChange={(e) => dispatch(actions.admin.setCustomerMapAddOrUpdate({
                   ...data,
                   value: e.target.value
               }))}/>
        <Select fieldProps={{
            label: "Operátor",
            required: true,
            helperText: "Operátor mappingu (např. =, >, <, ...)",
            invalid: validateModel.errors?.find(x => x.name === "op")?.isInvalid,
            errorText: validateModel.errors?.find(x => x.name === "op")?.error
        }}
                value={data.op}
                collection={opCollection}
                onValueChange={(e: any) => dispatch(actions.admin.setCustomerMapAddOrUpdate({
                    ...data,
                    op: e.value
                }))}/>
        <Input fieldProps={{
            label: "Výsledek",
            required: true,
            helperText: "Výsledek mappingu (např. LX_X, LB_X, LC_X, ...)",
            invalid: validateModel.errors?.find(x => x.name === "map")?.isInvalid,
            errorText: validateModel.errors?.find(x => x.name === "map")?.error
        }}
               value={data.map}
               onChange={(e) => dispatch(actions.admin.setCustomerMapAddOrUpdate({
                   ...data,
                   map: e.target.value
               }))}/>
    </Flex>
}