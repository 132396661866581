import {Comments} from "src/types/rts/Comments";
import {
    Avatar,
    Button,
    Card,
    Flex,
    icon,
    TimelineConnector,
    TimelineContent,
    TimelineItem,
    TimelineTitle,
    Text, TimelineRoot
} from "src/components";
import {TimelineDescription} from "src/components/ui/timeline";
import {useRedux} from "src/redux/provider";
import {useState} from "src/core";
import {SoDetailCommentForm} from "./so.detail.comment.form";

export default function SoDetailCommentItem(item: Comments, data: Comments[]) {
    const {useStore} = useRedux();
    const [reply, setReply] = useState(false);
    const user_id = useStore.users.logged?.user_id ?? -99;
    return (
        <TimelineItem>
            <TimelineConnector>
                <icon.LuPen/>
            </TimelineConnector>
            <TimelineContent>
                <Card>
                    <TimelineRoot size={"lg"} variant={"subtle"}>
                        <TimelineItem>
                            <TimelineConnector>
                                <Avatar name={item.user?.name}/>
                            </TimelineConnector>
                            <TimelineContent>
                                <Flex direction={"row"} justify={"space-between"} w={"100%"}>
                                    <Flex p={2} direction={"row"} gap={6} align={"center"}>
                                        <Flex direction={"column"}>
                                            <Flex direction={"column"}>
                                                <TimelineTitle
                                                    fontSize={"xs"}>{item.user?.name ?? "N/A"}</TimelineTitle>
                                                <TimelineDescription
                                                    fontSize={"2xs"}>{new Date(item.created_at ?? "1970-01-01").toLocaleString()}</TimelineDescription>
                                            </Flex>
                                            <Text textStyle="xs">
                                                {item.comment ?? ""}
                                            </Text>
                                        </Flex>
                                    </Flex>
                                    <Flex align={"center"} me={2}>
                                        <Button size={"xs"} variant={"ghost"}
                                                onClick={() => setReply(!reply)}>{reply ? <icon.LuX/> :
                                            <icon.LuReply/>} </Button>
                                        {user_id === item.user?.id &&
                                            <Button size={"xs"} variant={"ghost"}><icon.LuPen/></Button>}
                                        {user_id === item.user?.id &&
                                            <Button size={"xs"} variant={"ghost"}><icon.LuTrash/></Button>}
                                    </Flex>
                                </Flex>
                                {reply && <SoDetailCommentForm mt={6} p={2} parent={item.id}/>}
                            </TimelineContent>
                        </TimelineItem>
                        {data.filter((e) => e.parent?.id === item.id).map(x => SoDetailCommentItem(x, data))}
                    </TimelineRoot>
                </Card>
            </TimelineContent>
        </TimelineItem>
    );
}