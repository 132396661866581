import type {InputProps as ChakraInputProps} from "@chakra-ui/react"
import {
    AbsoluteCenter,
    Input as ChakraInput,
    Span,
    Spinner,
} from "@chakra-ui/react"
import {useState, useEffect, forwardRef, useRef} from "src/core";
import React from "react";
import {useRts} from "src/provider";
import {Button, Field, InputGroup, icon} from "src/components";


export interface PswProps extends ChakraInputProps {
    loading?: boolean
    label?: string
}

export const Psw = forwardRef<HTMLInputElement, PswProps>(
    function Psw(props, ref) {
        const {loading, label, disabled, type, ...rest} = props
        const {Redux} = useRts();
        const {useStore} = Redux()
        const is_loading = loading ?? useStore.core.loading;
        const [show, setShow] = useState(false)
        return (
            <Field label={label}>
                <InputGroup endElement={<Button size={"xs"} onClick={() => setShow(!show)}
                                                icon={!show ? <icon.LuEye/> : <icon.LuEyeOff/>}/>}>
                    <ChakraInput disabled={is_loading || disabled}
                                 type={!show ? "password" : "text"}
                                 {...rest} />
                </InputGroup>
            </Field>)


    }
)
