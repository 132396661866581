import {Card, Flex, Text, Avatar, icon} from "src/components";
import {useRts} from "src/provider";
import {CardHover} from "src/theme";
import {DialogSelectCustomer} from "src/views/serviceOrder/components/generic";

export default function DetailCustomer() {
    const {UseDialog, Redux} = useRts();
    const {useStore} = Redux();
    const data = useStore.service_order.detail;
    return (
        <Flex w={"100%"} direction={"column"}>
            <Card isHoverable={true} w={"100%"}
                  onClick={() => UseDialog({
                      open: true,
                      title: "Zákazník",
                      closable: true,
                      noPadding: true,
                      closeOnEscape: true,
                      bodyElement: <DialogSelectCustomer/>,
                  })} bodyElement={<><Text fontSize={"sm"} fontWeight={"bold"}>
                Zákazník
            </Text>
                <Flex
                    gap={6}
                    direction={"row"}
                    mt={4}
                >
                    <Avatar size={"sm"} me={6}/>
                    <Flex direction={"column"}>
                        <Text fontSize={"xs"} fontWeight={"bold"}>{data.so?.cardName}</Text>
                        <Text fontSize={"xs"}>{data.so?.cardICO}</Text>
                        <Text fontSize={"xs"}>{data.so?.cardDIC}</Text>
                    </Flex>
                    <Flex direction={"column"}>
                        <Text fontSize={"xs"} fontWeight={"bold"}>Email</Text>
                        <Text fontSize={"xs"}>{data.so?.cntctEmail}</Text>
                        <Text fontSize={"xs"}>{data.so?.cntctEmail2}</Text>
                    </Flex>
                    <Flex direction={"column"}>
                        <Text fontSize={"xs"} fontWeight={"bold"}>Mobil</Text>
                        <Text fontSize={"xs"}>{data.so?.cntctCellolar}</Text>
                    </Flex>
                </Flex></>}/>

            <Card mt={2} w={"100%"} isHoverable={true} bodyElement={<> <Text fontSize={"sm"} fontWeight={"bold"}>
                Fakturace
            </Text>
                <Flex
                    gap={6}
                    direction={"row"}
                    mt={4}
                >
                    <Avatar size={"sm"} me={6} icon={<></>}><icon.FaFileInvoiceDollar/></Avatar>
                    <Flex direction={"column"}>
                        <Text fontSize={"xs"} fontWeight={"bold"}>{data.so?.payToCode ?? data.so?.cardName}</Text>
                        <Text fontSize={"xs"} fontWeight={"bold"}>{data.so?.payToWorkplace}</Text>
                        <Text fontSize={"xs"}>{data.so?.payToStreet}</Text>
                        <Text
                            fontSize={"xs"}>{data.so?.payToZipCode}, {data.so?.payToCity}, {data.so?.payToCountry}</Text>
                    </Flex>
                    <Flex direction={"column"}>
                        <Text fontSize={"xs"} fontWeight={"bold"}>Email</Text>
                        <Text fontSize={"xs"}>{data.so?.cntctEmail}</Text>
                        <Text fontSize={"xs"}>{data.so?.cntctEmail2}</Text>
                    </Flex>
                    <Flex direction={"column"}>
                        <Text fontSize={"xs"} fontWeight={"bold"}>Mobil</Text>
                        <Text fontSize={"xs"}>{data.so?.cntctCellolar}</Text>
                    </Flex>
                </Flex></>}/>
            <Card mt={2} w={"100%"} isHoverable={true} bodyElement={<><Text fontSize={"sm"} fontWeight={"bold"}>
                Doručení
            </Text>
                <Flex
                    gap={6}
                    direction={"row"}
                    mt={4}
                >
                    <Avatar size={"sm"} me={6} icon={<></>}><icon.FaTruck/></Avatar>
                    <Flex direction={"column"}>
                        <Text fontSize={"xs"} fontWeight={"bold"}>{data.so?.shipToCode ?? data.so?.cardName}</Text>
                        <Text fontSize={"xs"} fontWeight={"bold"}>{data.so?.shipToWorkplace}</Text>
                        <Text fontSize={"xs"}>{data.so?.shipToStreet}</Text>
                        <Text
                            fontSize={"xs"}>{data.so?.shipToZipCode}, {data.so?.shipToCity}, {data.so?.shipToCountry}</Text>
                    </Flex>
                    <Flex direction={"column"}>
                        <Text fontSize={"xs"} fontWeight={"bold"}>Email</Text>
                        <Text fontSize={"xs"}>{data.so?.cntctEmail}</Text>
                        <Text fontSize={"xs"}>{data.so?.cntctEmail2}</Text>
                    </Flex>
                    <Flex direction={"column"}>
                        <Text fontSize={"xs"} fontWeight={"bold"}>Mobil</Text>
                        <Text fontSize={"xs"}>{data.so?.cntctCellolar}</Text>
                    </Flex>
                </Flex></>}/>
        </Flex>
    );
}
