import {useEffect,} from "src/items";
import {
    AbsoluteCenter, icon, Button, Flex, RadioCardItem, StatLabel, StatRoot, StatValueText,
    StepsContent,
    StepsItem, StepsList, StepsRoot, Text
} from "src/components";
import {NewGuid} from "src/lib";
import {useRedux} from "src/redux/provider";
import {Countries} from "src/enums";

export const ControllerForm = () => {
    const {useStore, dispatch, dispatchAsync, actions} = useRedux();
    const activeStep = useStore.service_request.activeStep;
    const all_ok = useStore.service_request.all_is_ok;

    useEffect(() => {
        validate_steps();
    }, [useStore.service_request]);

    const validate_steps = () => {
        let valid = true;
        const data = useStore.service_request;

        if (activeStep === 0) {
            var phone = data.customer.phone
                .replaceAll(" ", "")
                .replaceAll("_", "")
                .replace("+420", "")
                .replace("+421", "");
            if (data.customer.email.length === 0) valid = false;
            if (data.customer.first_name.length === 0) valid = false;
            if (data.customer.last_name.length === 0) valid = false;
            if (data.customer.street.length === 0) valid = false;
            if (data.customer.city.length === 0) valid = false;
            if (
                data.customer.postal_code.replaceAll("_", "").replace(" ", "")
                    .length !== 5
            )
                valid = false;
            if (phone.length !== 9) valid = false;

            if (data.invoice_type === "2") {
                if (
                    data.invoice_address?.company_id.replace(" ", "").replace("_", "")
                        .length !== 8 ||
                    data.invoice_address?.city.length === 0
                )
                    valid = false;
            }

            if (data.invoice_type === "3") {
                var phone_inv = (data.invoice_address?.phone ?? "")
                    .replaceAll(" ", "")
                    .replaceAll("_", "")
                    .replace("+420", "")
                    .replace("+421", "");
                if (data.invoice_address?.first_name.length === 0) valid = false;
                if (data.invoice_address?.last_name.length === 0) valid = false;
                if (data.invoice_address?.street.length === 0) valid = false;
                if (data.invoice_address?.city.length === 0) valid = false;
                if (
                    data.invoice_address?.postal_code.replaceAll("_", "").replace(" ", "")
                        .length !== 5
                )
                    valid = false;
                if (data.invoice_address?.country.length === 0) valid = false;
                if (phone_inv.length > 0) {
                    //Pokud je vyplněný telefon tak ho kontrolujeme
                    if (phone_inv.length !== 9) valid = false;
                }
            }
        }

        if (activeStep === 1) {
            const data = useStore.service_request.devices;
            if (data.length === 0) valid = false;
        }

        if (activeStep === 2) {
            const data = useStore.service_request;

            if (data.pickup_type === null) valid = false;
            if (data.delivery_type === null) valid = false;
            if ((data.pickup_type ?? "1") !== "1")
                if (!useStore.shared.is_valid_pickup_date) valid = false;

            if (data.pickup_type === "4") {
                var phone_pickup = (data.pickup_address?.phone ?? "")
                    .replaceAll(" ", "")
                    .replaceAll("_", "")
                    .replace("+420", "")
                    .replace("+421", "");
                if (data.pickup_address?.first_name.length === 0) valid = false;
                if (data.pickup_address?.street.length === 0) valid = false;
                if (data.pickup_address?.city.length === 0) valid = false;
                if (
                    data.pickup_address?.postal_code.replaceAll("_", "").replace(" ", "")
                        .length !== 5
                )
                    valid = false;
                if (data.pickup_address?.country.length === 0) valid = false;
                if (phone_pickup.length > 0 && phone_pickup.length !== 9) valid = false;
            }
            if (data.delivery_type === "5") {
                var phone_delivery = (data.delivery_address?.phone ?? "")
                    .replaceAll(" ", "")
                    .replaceAll("_", "")
                    .replace("+420", "")
                    .replace("+421", "");
                if (data.delivery_address?.first_name.length === 0) valid = false;
                if (data.delivery_address?.street.length === 0) valid = false;
                if (data.delivery_address?.city.length === 0) valid = false;
                if (
                    data.delivery_address?.postal_code
                        .replaceAll("_", "")
                        .replace(" ", "").length !== 5
                )
                    valid = false;
                if (data.delivery_address?.country.length === 0) valid = false;
                if (phone_delivery.length > 0 && phone_delivery.length !== 9)
                    valid = false;
            }
        }

        return valid;
    };

    const full_validate = () => {
        return false;
    };

    return (
        <Flex mt={4} direction={"row"} justify={"space-between"}>
            {activeStep < 4 && (
                <Button
                    variant={"subtle"}
                    onClick={() => dispatch(actions.service_request.beforeStep())}
                    disabled={activeStep < 1}
                >
                    <icon.AiOutlineArrowLeft/>
                    <Text ms={2}>Zpět</Text>
                </Button>
            )}
            {activeStep < 3 && (
                <Button
                    variant={"subtle"}
                    colorPalette={"green"}
                    onClick={() => dispatch(actions.service_request.nextStep())}
                    disabled={activeStep === 3 || !validate_steps()}
                >
                    <Text me={2}>Dále</Text>
                    <icon.AiOutlineArrowRight/>
                </Button>
            )}
            {activeStep === 3 && (
                <Button
                    variant={"subtle"}
                    colorPalette={"green"}
                    onClick={() =>
                        dispatchAsync(actions.service_request.signalR.createServiceRequest({
                            ...useStore.service_request,
                            selectable_address: []
                        }))
                    }
                    disabled={activeStep !== 3 || !full_validate || !all_ok}
                    loading={useStore.core.loading}
                >
                    <Text me={2}>Odeslat</Text>
                    <icon.AiOutlineSend/>
                </Button>
            )}
        </Flex>
    );
};

export const Card_type = (props: {
    label: string;
    value: string;
    descriptionTop?: React.ReactNode;
    descriptionBottom?: React.ReactNode;
    curr?: string;
    isPrice?: boolean;
    isFill?: boolean;
    price?: number;
    display?: string;
}) => {
    return (
        <RadioCardItem
            w={"100%"}
            label={props.label}
            display={props.display}
            description={
                <Flex direction={"column"} w="100%" gap={2}>
                    {props.descriptionTop}
                    {(props.isFill ?? false) && <Flex mt={6}></Flex>}
                    {(props.isPrice ?? false) && (
                        <StatRoot>
                            <StatLabel>Cena za dopravné</StatLabel>
                            <StatValueText
                                fontSize={"sm"}
                                value={props.price}
                                formatOptions={{
                                    style: "currency",
                                    currency: props.curr ?? "CZK",
                                }}
                            />
                        </StatRoot>
                    )}
                    {props.descriptionBottom}
                </Flex>
            }
            key={NewGuid()}
            value={props.value}
        />
    );
};

export const InvoiceAddress = () => {
    const {useStore} = useRedux();
    const data = useStore.service_request;
    if (data.invoice_type !== "1") {
        return (
            data.invoice_address?.street +
            ", " +
            data.invoice_address?.city +
            ", " +
            data.invoice_address?.postal_code +
            ", " +
            data.invoice_address?.country
        );
    } else {
        return (
            data.customer.street +
            ", " +
            data.customer.city +
            ", " +
            data.customer.postal_code +
            ", " +
            data.customer.country
        );
    }
};

export const InvoiceName = () => {
    const {useStore} = useRedux();
    const data = useStore.service_request;
    if (data.invoice_type !== "1") {
        return data.invoice_address?.first_name;
    } else {
        return data.customer.first_name + " " + data.customer.last_name;
    }
};

export const InvoiceICO = () => {
    const {useStore} = useRedux();
    const data = useStore.service_request;
    if (data.invoice_type !== "1") {
        return data.invoice_address?.company_id;
    } else {
        return "";
    }
};

export const PickupContact = () => {
    const {useStore} = useRedux();
    const data = useStore.service_request;
    if (data.pickup_type !== "1") {
        if (data.pickup_type === "2")
            return data.customer.first_name + " " + data.customer.last_name;
        else if (data.pickup_type === "3") return data.invoice_address?.first_name;
        else return data.pickup_address?.first_name;
    }
};

export const DeliveryContact = () => {
    const {useStore} = useRedux();
    const data = useStore.service_request;
    if (data.delivery_type !== "1") {
        if (data.delivery_type === "2")
            return data.customer.first_name + " " + data.customer.last_name;
        else if (data.delivery_type === "4") return PickupContact();
        else if (data.delivery_type === "3")
            return data.invoice_address?.first_name;
        else return data.delivery_address?.first_name;
    }
};

export const PickupCountry = (): "CZ" | "SK" => {
    const {useStore} = useRedux();
    const data = useStore.service_request;
    if (data.pickup_type === null) return Countries.Czech;
    if (data.pickup_type === "1") return Countries.Czech;
    if (data.pickup_type === "2") return data.customer?.country ?? Countries.Czech === Countries.Czech ? Countries.Czech : Countries.Slovakia;
    if (data.pickup_type === "3") return data.invoice_address?.country ?? Countries.Czech === Countries.Czech ? Countries.Czech : Countries.Slovakia;
    if (data.pickup_type === "4") return data.pickup_address?.country ?? Countries.Czech === Countries.Czech ? Countries.Czech : Countries.Slovakia;
    return Countries.Czech;
};

export const PickupAddress = () => {
    const {useStore} = useRedux();
    const data = useStore.service_request;

    if (data.pickup_type) {
        if (data.pickup_type === "3") {
            return (
                data.invoice_address?.street +
                ", " +
                data.invoice_address?.city +
                ", " +
                data.invoice_address?.postal_code +
                ", " +
                data.invoice_address?.country
            );
        }
        if (data.pickup_type === "4") {
            return (
                data.pickup_address?.street +
                ", " +
                data.pickup_address?.city +
                ", " +
                data.pickup_address?.postal_code +
                ", " +
                data.pickup_address?.country
            );
        }
        if (data.pickup_type === "1" || data.pickup_type === "2") {
            return (
                data.customer.street +
                ", " +
                data.customer.city +
                ", " +
                data.customer.postal_code +
                ", " +
                data.customer.country
            );
        }
    }
    return "";
};

export const DeliveryAddress = () => {
    const {useStore} = useRedux();
    const data = useStore.service_request;
    if (data.delivery_type !== "1") {
        if (data.delivery_type === "3") {
            return InvoiceAddress();
        }
        if (data.delivery_type === "5") {
            return (
                data.delivery_address?.street +
                ", " +
                data.delivery_address?.city +
                ", " +
                data.delivery_address?.postal_code +
                ", " +
                data.delivery_address?.country
            );
        }
        if (data.delivery_type === "4") {
            return PickupAddress();
        }
        if (data.delivery_type === "2") {
            return (
                data.customer.street +
                ", " +
                data.customer.city +
                ", " +
                data.customer.postal_code +
                ", " +
                data.customer.country
            );
        }
    }
    return "";
};

export const Beggin = () => {
    const {useStore, dispatch, actions} = useRedux();
    const activeStep = useStore.service_request.activeStep;
    if (activeStep === -1) {
        return (
            <AbsoluteCenter mt={"100px"} height={"100vh"} alignContent={"center"}>
                <Button
                    onClick={() => dispatch(actions.service_request.nextStep())}
                >
                    Zadat nový požadavek
                </Button>
            </AbsoluteCenter>
        );
    }
    return null;
};

export const Stepper = () => {
    const {useStore, dispatch, actions} = useRedux();
    const activeStep = useStore.service_request.activeStep;
    const stepperType = window.innerWidth < 600 ? "vertical" : "horizontal";
    const steps = [
        {title: "Zákazník", description: ""},
        {title: "Zařízení", description: ""},
        {title: "Doprava", description: ""},
        {title: "Souhrn", description: ""},
    ];
    return (
        <StepsRoot
            orientation={stepperType}
            variant={"solid"}
            colorPalette={"green"}
            count={steps.length}
            size={"sm"}
            defaultStep={activeStep}
            step={activeStep}
        >
            <StepsList>
                {steps.map((step, index) => (
                    <StepsItem key={`si_{index}_${step.title}`} index={index} title={step.title}/>
                ))}
            </StepsList>
            {steps.map((step, index) => (
                <StepsContent key={`st_${index}_${step.title}`} index={index}>{step.description}</StepsContent>
            ))}
        </StepsRoot>
    );
};
