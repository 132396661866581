import {Avatar, Card, icon, Flex, Text} from "src/components";
import {CardHover} from "src/theme";
import React from "react";

export function Config() {
    return (
        <Flex
            direction={"row"}
            gap={4}
            align={"center"}
            alignItems={"center"}
            justifyContent={"space-between"}
        >
            <Avatar icon={<></>}><icon.LuBellDot/></Avatar>
            <Flex direction={"column"}>
                <Text>
                    Notifikační centrum
                </Text>
                <Text fontSize={"xs"}></Text>
            </Flex>
            <Flex direction={"column"}>
                <Text fontSize={"sm"}></Text>
                <Text fontSize={"xs"}>
                    Správa netifikací, upozornění, emailových zpráv
                </Text>
            </Flex>
            <icon.FaArrowRight/>
        </Flex>
    )
}