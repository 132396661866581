import {NewGuid} from "src/lib";
import type {Ares2Inlfo, Shared} from "src/types/shared";
import type {UserDetail} from "src/types/users";

export const retriveFirstPickupDate = (state: Shared, action: { payload: string }) => {
    state.first_pickup_date = action.payload;
};

export const validatePickupDate = (state: Shared, action: { payload: boolean }) => {
    state.is_valid_pickup_date = action.payload;
};
export const getAuthorized = (state: Shared, action: { payload: UserDetail[] }) => {
    state.is_authorized = action.payload !== null && action.payload.length === 1;
    if (state.is_authorized) {
        state.e_mail = action.payload[0].unique_fullname ?? "";
        state.token_verified = action.payload[0].access_token ?? "";
    }
};

export const getHolidays = (state: Shared, action: { payload: string[] }) => {
    state.holidays = action.payload;
}

export const validateEmail = (state: Shared, action: { payload: boolean }) => {
    //Po úspěšném odeslání emailu nastavíme nějaký token, který určuje, že email byl odeslán a čeká se na kliknutí na odkaz z emailu
    if (action.payload)
        state.token_for_verify = NewGuid();

};

export const verifyToken = (state: Shared, action: {
    payload: UserDetail[]
}) => {
    //Po úspěšném odeslání emailu nastavíme nějaký token, který určuje, že email byl odeslán a čeká se na kliknutí na odkaz z emailu
    if (action.payload.length === 1) {
        state.token_verified = action.payload[0].access_token ?? "";
        state.is_authorized = action.payload[0].access_token !== null;
        state.e_mail = action.payload[0].unique_fullname ?? "";
    }
};

export const retrieveCompanyInfo = (state: Shared, action: { payload: Ares2Inlfo }) => {
    state.api_ico = action.payload;
};

export const getHelp = (state: Shared, action: { payload: string }) => {
    state.help = action.payload;
};

export const retrieveVendors = (state: Shared, action: {
    payload: typeof state.vendors
}) => {
    if (action.payload !== null)
        state.vendors = action.payload;
}

export const retrieveCountries = (state: Shared, action: {
    payload: typeof state.countries
}) => {
    if (action.payload !== null)
        state.countries = action.payload;
}

export const retrieveKubernetesToken = (state: Shared, action: { payload: typeof state.kubernetes_token }) => {
    state.kubernetes_token = action.payload;
}

