import Enhancer, {Generate} from "src/redux/enhancer";
import {PriceProposalAdminfetchActions} from "src/redux/features/admin/admin.actions.priceproposal";
import {NotificationAdminfetchActions} from "src/redux/features/admin/admin.actions.notification";
import {EnhancersFrom, signalR} from "src/enums";


export const signalRActions = (storeName: string) => {
    return {
        GetServerStatus: () => Generate(storeName, "", "getServerStatus", EnhancersFrom.signalR, signalR.core.serverState),
        SetServerReboot: () => Generate(storeName, "", "setServerReboot", EnhancersFrom.signalR, signalR.core.serverReboot),
    }
}

export const fetchActions = (storeName: string) => {
    return {
        ...PriceProposalAdminfetchActions(storeName),
        ...NotificationAdminfetchActions(storeName),
    }
};
export const extraActions = (storeName: string) => {
    return {}
};

export function ActionsEnhancer<T>(action: T, storeName: string) {
    return Enhancer(action, signalRActions(storeName), fetchActions(storeName), extraActions(storeName));
}