import type {InputProps as ChakraInputProps} from "@chakra-ui/react"
import {
    AbsoluteCenter,
    Input as ChakraInput,
    Span,
    Spinner,
} from "@chakra-ui/react"
import {useRedux} from "src/redux/provider";
import ReactInputMask from "react-input-mask";
import {useState, useEffect, forwardRef, useRef} from "src/core";
import {Field} from "src/components";
import {FieldProps} from "src/components/ui/field";

interface InputLoadingProps {
    loading?: boolean
    loadingText?: React.ReactNode
}

export interface InputProps extends ChakraInputProps, InputLoadingProps {
    mask?: string
    fieldProps?: FieldProps
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
    function Input(props, ref) {
        const {loading, disabled, loadingText, fieldProps, value, ...rest} = props
        const inputRef = useRef<HTMLInputElement>(null);
        let mask = props.mask ?? "";
        const {useStore} = useRedux()
        let is_loading = loading ?? useStore.core.loading;
        const [inputClassName, setInputClassName] = useState("");
        useEffect(() => {
            if (inputRef.current) {
                ref = inputRef;
                // Získejte dynamicky vygenerovanou třídu
                const className = inputRef.current.className;
                setInputClassName(className);
            }
        }, []);

        return (
            <Field {...fieldProps}>
                <ChakraInput disabled={is_loading || disabled} style={{display: mask !== "" ? "none" : undefined}}
                             ref={inputRef} value={value}
                             {...rest} />
                <ReactInputMask mask={mask} style={{display: mask === "" ? "none" : undefined}}
                                className={`chakra-input ${inputClassName}`} onChange={rest.onChange} value={value}/>
            </Field>)


    },
)
