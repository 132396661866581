import {useRts} from "src/provider";

export default function DetailDevice() {
    const {Components, Redux} = useRts();
    const {useStore, dispatch, dispatchAsync, actions} = Redux();
    const {Flex, Avatar, icon, Input, Text, Textarea} = Components;
    const data = useStore.service_order.detail;
    return <Flex gap={2} direction={"column"}>
        <Flex>
            <Input size={"sm"}
                   value={data.udf?.inRepType}
                   fieldProps={{
                       label: "Vstupní typ opravy"
                   }}/>
            <Input size={"sm"}
                   value={data.so?.deviceRepairType}
                   fieldProps={{
                       label: "Výstupní typ opravy"
                   }}/>
        </Flex>
        <Flex direction={"row"} gap={4} w={"100%"}>
            <Flex w={"30%"}>
                <Avatar size={"full"} icon={<></>} borderRadius={"15px"} fontSize={"120px"}>
                    <icon.FaMobile/>
                </Avatar>
            </Flex>

            <Flex direction={"column"} w={"70%"} gap={2}>
                <Input size={"sm"}
                       value={data.deviceType?.typeName}
                       fieldProps={{
                           label: "Typ Přístroje"
                       }}/>
                <Input size={"sm"}
                       value={data.so?.deviceSN}
                       fieldProps={{
                           label: "IMEI"
                       }}/>
                <Input size={"sm"}
                       value={data.udf?.productCodeSN}
                       fieldProps={{
                           label: "S/N"
                       }}/>
                <Flex gap={2}>
                    <Input size={"sm"}
                           value={new Date(data.udf?.datExtPr ?? "1970-01-01").toLocaleDateString()}
                           fieldProps={{
                               label: "Externě přijato"
                           }}/>
                    <Input size={"sm"}
                           value={new Date(data.udf?.datProdej ?? "1970-01-01").toLocaleDateString()}
                           fieldProps={{
                               label: "Datum prodeje"
                           }}/>
                </Flex>

            </Flex>
        </Flex>
        <Flex direction={"column"} gap={2}>
            <Textarea placeholder={"...stav zařízení"} />
            <Flex direction={"row"} gap={4}>
                <Input size={"sm"}
                       value={data.so?.deviceDefect1}
                       fieldProps={{
                           label: "Závada/y"
                       }}/>
            </Flex>
            <Textarea placeholder={"...popis závady"} />
        </Flex>
    </Flex>
}