import {Admin} from "src/types/admin";

export const initialState: Admin = {
    Notification: {
        template: ""
    },
    Server: {
        connections: 0,
        serverTime: new Date(),
        processId: 0,
        machineName: "",
        versionString: "",
        processorCount: 0,
        systemDirectory: "",
        version: "",
        currentManagedThreadId: 0,
        currentDirectory: "",
    },
    PriceProposalConfig: {
        state: false,
        config: [],
        customers: {
            lookup: [],
            map: {
                page_size: 50,
                page_number: 1,
                data: [],
                total: 0,
                count: 0,
                is_next: false,
                is_prev: false,
            },
            map_addorupdate: {
                id: 0,
                desc: "",
                customer: "",
                op: ["="],
                type: ["level"],
                value: "",
                map: "",
            },
            selected: {value: "", label: ""},
            data: {
                page_size: 50,
                page_number: 1,
                data: [],
                total: 0,
                count: 0,
                is_next: false,
                is_prev: false,
            },
            data_addorupdate: {
                id: 0,
                is_block_email: false,
                query: "",
                emailTo: "[]",
                emailCc: "[]",
            }
        },
        words: {
            data_addorupdate: {
                id: 0,
                text: "",
                language: "CZ",
                type: "",
                photo_count: 0,
                vendor: {
                    id: 0,
                    vendor_code: "0",
                }
            },
            data: {
                data: [],
                page_number: 1,
                page_size: 20,
                total: 0,
                count: 0,
                is_next: false,
                is_prev: false,
            }
        },

    }
};