import React from "react";
import {useRts} from "src/provider";

export function CustomerOther() {
    const {Redux, Components} = useRts();
    const {useStore, dispatch, actions, dispatchAsync} = Redux();
    const selected = useStore.admin.PriceProposalConfig.customers.selected;
    const data = useStore.admin.PriceProposalConfig.customers.data;
    const {CheckboxCard, Flex} = Components;
    return (
        <Flex direction={"column"} gap={2}>
            <CheckboxCard
                label={"Dopravné se sčítá"}
                checked={data?.data?.[0]?.is_sum_shipments ?? false}
                onChange={(e: any) => dispatchAsync(actions.admin.fetch.AddOrUpdateCustomers({
                    page_number: data.page_number,
                    page_size: data.page_size,
                    data: {
                        ...data?.data?.[0] ?? {
                            id: 0,
                            query: "sum_shipments",
                            emailTo: "",
                            emailCc: "",
                            customer: selected.value
                        },
                        is_sum_shipments: e.target.checked,
                    }
                }))}
                description={"Pokud je toto zaškrtlé tak se cena za dopravné sčítá. To znamená, že se cena do cenové nabídky dostane cena za dopravné: svozová + budoucí rozvozová"}/>
        </Flex>
    )
}