import {FilterContent, FilterFooter} from "./filter.content";
import {useRts} from "src/provider";

export function Filter() {
    const {UseDrawer, Components} = useRts();
    const {Flex, InputGroup, Button, Input, icon} = Components;
    return (
        <Flex gap={2}>
            <InputGroup
                startElement={<icon.FaSearch/>}
                endElement={
                    <Button variant={"subtle"} size={"xs"} onClick={() => UseDrawer({
                        title: "Vyhledávání zakázek",
                        open: true,
                        closable: true,
                        size: "md",
                        sidebarItem: null,
                        bodyElement: <FilterContent/>,
                        footerElement: <FilterFooter/>
                    })}>
                        <icon.MdFilterList/>
                    </Button>
                }
            >
                <Input placeholder="Hledat..."/>
            </InputGroup>
        </Flex>
    );
}
