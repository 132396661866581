import {
    Button,
    Flex,
    MenuTrigger,
    MenuContent,
    MenuItem,
    Badge,
    useColorModeValue,
    Avatar,
    SkeletonCircle,
    Skeleton,
    ColorModeButton,
    MenuRoot,
    Status,
    icon,
} from "../../items";
import SidebarResponsive from "../Sidebar/SidebarResponsive";
import NavbarAlerts from "./navbarAlerts";
import {MenuItemGroup, MenuSeparator} from "src/components/ui/menu";
import {useRts} from "src/provider";
import {InputType} from "src/enums";

export default function HeaderLinks() {
    const {Redux, UseDialog, UseForm} = useRts();
    const {useStore, dispatch, actions} = Redux();
    let navbarIcon = useColorModeValue("gray.500", "gray.200");

    function UserInfoAvatar() {
        if (useStore.users.isAuth) {
            return (
                <Avatar
                    size="sm"
                    src={`data:image/png;base64,${useStore.users.logged?.id}`}
                />
            );
        } else {
            return (
                <Flex direction={"row"}>
                    <SkeletonCircle size="10"/>
                </Flex>
            );
        }
    }

    function UserInfoName() {
        if (useStore.users.isAuth) {
            return <>{(useStore.users.logged?.name ?? useStore.users.logged?.username ?? "").split(" ")[0]}</>;
        } else {
            return (
                <Flex direction={"row"} w={"100%"}>
                    <Skeleton height="20px" width={"100px"}/>
                </Flex>
            );
        }
    }

    return (
        <Flex
            pe={{sm: "0px", md: "16px"}}
            w={{sm: "100%", md: "auto"}}
            mt={{sm: "15px", md: "0px"}}
            alignItems="center"
            justifyItems={"end"}
            flexDirection="row"
            maxHeight={"18px"}
        >
            <Badge
                display={{sm: "none", md: "block"}}
                borderRadius={"15px"}
                alignContent={"center"}
                px={"10px"}
            >
                <Flex
                    align={"center"}
                    alignItems={"center"}
                    alignContent={"center"}
                    alignSelf={"center"}
                    gap={2}
                >
                    <Status value={useStore.core.signalr.state ? "success" : "error"}/>
                    SignalR
                </Flex>
            </Badge>
            <MenuRoot>
                <MenuTrigger>
                    <Button
                        me={{sm: "2px", md: "16px"}}
                        color={navbarIcon}
                        variant="ghost"
                        cursor={"pointer"}
                    >
                        <UserInfoAvatar/>
                        <UserInfoName/>
                    </Button>
                </MenuTrigger>
                <MenuContent p="16px 8px">
                    <Flex flexDirection="column" fontSize={"sm"}>
                        <MenuItemGroup>
                            <MenuItem disabled={useStore.core.loading} value="NbLinkProfile">
                                <icon.FaUser style={{marginRight: "15px"}}/> Profil
                            </MenuItem>
                            <MenuItem disabled={useStore.core.loading} value="NbLinkSettings">
                                <icon.FaCog style={{marginRight: "15px"}}/> Nastavení
                            </MenuItem>
                            <MenuItem
                                disabled={useStore.core.loading}
                                value="NbLinkLogOff"
                                onClick={() => {
                                    dispatch(actions.users.fetch.LogOut());
                                }}
                            >
                                <icon.FaLock style={{marginRight: "15px"}}/> Odhlásit se
                            </MenuItem>
                        </MenuItemGroup>
                        <MenuSeparator my={2}/>
                        <MenuItemGroup>
                            <MenuItem disabled={useStore.core.loading} value="NbLinkChange" onClick={() => UseDialog({
                                open: true,
                                title: "Změna hesla",
                                closable: true,
                                bodyElement: <Flex direction={"column"} gap={4}>
                                    <UseForm type={InputType.password} label={"Staré heslo"}/>
                                    <UseForm type={InputType.password} label={"Nové heslo"}/>
                                    <UseForm type={InputType.password} label={"Potvrzení nového hesla"}/>
                                </Flex>,
                                footerElement: <Flex direction={"row"} gap={4}>
                                    <Button variant={"surface"}>Uložit</Button>
                                </Flex>
                            })}>
                                <icon.LuKey style={{marginRight: "15px"}}/> Změnit heslo
                            </MenuItem>
                            <MenuItem disabled={useStore.core.loading} value="NbLinkHelp">
                                <icon.FaQuestion style={{marginRight: "15px"}}/> Nápověda
                            </MenuItem>
                        </MenuItemGroup>
                    </Flex>
                </MenuContent>
            </MenuRoot>
            <NavbarAlerts/>
            <ColorModeButton ms={2}/>
            <SidebarResponsive/>
        </Flex>
    );
}
