import {Button, Card, Flex, Input, icon, Tag, Text} from "src/components";
import React from "react";
import {useRedux} from "src/redux/provider";

export function DetailShipments() {
    const {useStore, dispatchAsync, actions} = useRedux();
    const data = useStore.service_order.detail.transp;
    return (
        <Flex w={"100%"} direction={"column"} gap={2}>
            {data.map((item) => (
                <Card key={`items_${item.transpNo}`} w={"100%"} bodyElement={<Flex justifyContent={"space-between"}>
                    <Flex direction={"column"}>
                        <Text>
                            {item.transpItemCode}
                        </Text>
                        <Text fontSize={"xs"}>{item.transpItemCode}</Text>
                    </Flex>
                    <Flex direction={"column"}>
                        <Flex justifyContent={"end"}>
                            <Tag>{item.unitPrice} {useStore.service_order.currency}</Tag>
                        </Flex>
                        <Flex justifyContent={"end"}>
                            <Tag
                                colorPalette={"green"}>{item.reasonType}</Tag>
                        </Flex>
                    </Flex>
                </Flex>}/>
            ))}
        </Flex>
    );
}
