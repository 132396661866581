import React from "react";
import {Flex, Input, InputGroup, icon, Button} from "src/components";
import {AdminItem} from "src/types/admin";
import AdminPriceProposalConfig from "src/views/admin/components/PriceProposal/config";
import {Words} from "src/views/admin/components/PriceProposal/words";
import AdminRenderItem from "../admin/item";
import {Customer} from "src/views/admin/components/PriceProposal";
import {PriceList, SpecialPrices} from "src/views/admin/components/PriceList";
import {useRedux} from "src/redux/provider";
import {Template} from "src/views/admin/components/Notification";
import {__server} from "src/config";
import {KubernetesHeader} from "src/views/admin/components/Kubernetes-header";
import {ServerActions, ServerState} from "src/views/admin/components/Server";


export function Admin() {
    const {dispatchAsync, dispatch, useStore, actions} = useRedux();
    const Items: Array<AdminItem> = [
        {
            icon: icon.SiAutomattic,
            title: "Automatizace",
            description: "Správa automatických úloh (Cron)",
            dispatcher: {
                open: true,
                size: "full",
                sidebar: false,
                bodyElement: <Flex width={"100%"} height={"90vh"} borderRadius={"15px"}>
                    <iframe src={`${__server}/cron`} style={{width: "100%", height: "100%", borderRadius: "15px"}}/>
                </Flex>,
                closable: true,
            }
        },
        {
            icon: icon.SiKibana,
            title: "Logy",
            description: "Sledování a dohled nad RTS (Elasticsearch, kibana), Pro zobrazení logů je nutné být připojen k VPN nebo se nacházet v lokální síti",
            dispatcher: {
                open: true,
                size: "full",
                sidebar: false,
                bodyElement: <Flex width={"100%"} height={"90vh"} borderRadius={"15px"}>
                    <iframe
                        src={`https://kibana.britex.cz/app/discover#/view/9267099a-ad3d-43f5-b3cb-674a3587f2d6?_g=(filters:!(),refreshInterval:(pause:!f,value:1000),time:(from:now-6h,to:now))&_a=(columns:!(fields.Method,fields.Path,fields.StatusCode,fields.SourceContext,message),dataSource:(dataViewId:'489ab879-af28-4be3-af48-4e0c7b26d1bc',type:dataView),density:compact,filters:!(),grid:(columns:(fields.Method:(width:58),fields.Path:(width:113),fields.SourceContext:(width:356),fields.StatusCode:(width:51),fields.ThreadName:(width:306))),headerRowHeight:0,hideChart:!f,interval:auto,query:(language:kuery,query:''),rowHeight:0,rowsPerPage:500,sort:!(!('@timestamp',desc)),viewMode:documents)`}
                        style={{width: "100%", height: "100%", borderRadius: "15px"}}/>
                </Flex>,
                closable: true,
            }
        },
        {
            icon: icon.SiKubernetes,
            title: "Kubernetes",
            description: "Sledování micro-services. Pro zobrazení micro-services je nutné být připojen k VPN nebo se nacházet v lokální síti",
            dispatcher: {
                open: true,
                size: "full",
                sidebar: false,
                headerElement: <KubernetesHeader/>,
                bodyElement: <Flex width={"100%"} height={"90vh"} borderRadius={"15px"}>
                    <iframe src={`https://k8s.britex.cz`}
                            style={{width: "100%", height: "100%", borderRadius: "15px"}}/>
                </Flex>,
                closable: true,
            }
        },
        {
            icon: icon.SiRedis,
            title: "Redis Cache",
            description: "Cache server pro ukládání dočasných dat. Pro zobrazení micro-services je nutné být připojen k VPN nebo se nacházet v lokální síti",
            dispatcher: {
                open: true,
                size: "full",
                sidebar: false,
                bodyElement: <Flex width={"100%"} height={"90vh"} borderRadius={"15px"}>
                    <iframe src={`https://redis.britex.cz`}
                            style={{width: "100%", height: "100%", borderRadius: "15px"}}/>
                </Flex>,
                closable: true,
            }
        },
        {
            icon: icon.LuUser,
            title: "Uživatelé",
            description: "Správa uživatelů",
            dispatcher: {
                open: true,
                title: "Nastavení uživatelů",
                size: "xl",
                sidebar: true,
                sidebarValue: "internal",
                sidebarItem: null,
                sidebarItems: [
                    {
                        name: "Interní",
                        value: "internal",
                        icon: icon.LuUser,
                        element: <div>Uživatelé</div>
                    },
                    {
                        name: "Externí",
                        value: "external",
                        icon: icon.LuUser,
                        element: <div></div>
                    }
                ],
                closable: true,
            }
        },
        {
            icon: icon.LuShield,
            title: "Aplikace a oprávnění",
            description: "Správa aplikací a oprávnění pro autorizační server",
            dispatcher: {
                open: true,
                sidebarItem: null,
                title: "Nastavení cenových nabídek",
                size: "xl",
                sidebar: true,
                sidebarValue: "config",
                sidebarItems: [
                    {
                        name: "Aplikace",
                        value: "apps",
                        icon: icon.LuAppWindow,
                        element: <></>
                    },
                    {
                        name: "Api klíče",
                        value: "apikeys",
                        icon: icon.LuCode,
                        element: <></>
                    }
                ],
                closable: true,
            }
        },
        {
            icon: icon.LuBell,
            title: "Notifikace",
            description: "Správa notifikací",
            dispatcher: {
                open: true,
                sidebarItem: null,
                title: "Nastavení notifikací, reportů a šablony pro emailové notifikace",
                size: "xl",
                sidebar: true,
                sidebarValue: "config",
                sidebarItems: [
                    {
                        name: "Proměnné",
                        value: "config",
                        icon: icon.LuVariable,
                        element: <></>
                    },
                    {
                        name: "Triggery",
                        value: "words",
                        icon: icon.LuFlagTriangleLeft,
                        element: <></>
                    },
                    {
                        name: "Šablona",
                        value: "template",
                        icon: icon.LuLayoutTemplate,
                        element: <Template/>
                    }
                ],
                closable: true,
            }
        },
        {
            icon: icon.LuFileSpreadsheet,
            title: "Cenové nabídky",
            description: "Správa cenových nabídek a konfigurace cenových nabídek",
            dispatcher: {
                open: true,
                sidebarItem: null,
                title: "Nastavení cenových nabídek",
                size: "xl",
                sidebar: true,
                sidebarValue: "config",
                sidebarItems: [
                    {
                        name: "Obecné",
                        value: "config",
                        icon: icon.LuCog,
                        element: <AdminPriceProposalConfig/>
                    },
                    {
                        name: "Dle výrobce",
                        value: "words",
                        icon: icon.LuWholeWord,
                        element: <Words/>
                    },
                    {
                        name: "Dle zákazníka",
                        value: "words",
                        icon: icon.LuUsers,
                        element: <Customer/>
                    }
                ],
                closable: true,
            }
        },
        {
            icon: icon.LuEuro,
            title: "Ceníky",
            description: "Správa ceníků prací materiálů nebo konfigurace speciálních cen pro obchodní partnery",
            dispatcher: {
                open: true,
                sidebarItem: null,
                title: "Nastavení ceníků",
                size: "xl",
                sidebar: true,
                sidebarValue: "config",
                sidebarItems: [
                    {
                        name: "Ceníky",
                        value: "price-list",
                        icon: icon.LuReceiptEuro,
                        element: <PriceList/>
                    },
                    {
                        name: "Speciální ceny dle zákazníka",
                        value: "special-prices",
                        icon: icon.LuUsers,
                        element: <SpecialPrices/>
                    }
                ],
                closable: true,
            }
        },
        {
            icon: icon.LuPowerOff,
            title: "Stav serveru",
            description: "Zobrazení stavu služeb, serveru a aplikací, restart služeb",
            dispatcher: {
                open: true,
                title: "Server stav",
                size: "xl",
                sidebar: false,
                bodyElement: <ServerState/>,
                footerElement: <ServerActions/>,
                closable: true,
            }
        }
    ]


    const priceProposalConfig = () => dispatchAsync(actions.admin.fetch.GetConfig());

    return (
        <Flex direction={"column"} gap={4}>
            <Flex justifyContent={"flex-end"} w={"100%"}>
                <InputGroup w={"30%"} startElement={<icon.LuSearch/>}>
                    <Input placeholder={"Co budeme hledat ?..."}/>
                </InputGroup>
            </Flex>
            <Flex direction={"column"} gap={2}>
                {Items.map((item: AdminItem) => (
                    <AdminRenderItem key={item}
                                     onBeforeLoad={() => item.title === "Cenové nabídky" ? priceProposalConfig() : {}} {...item} />
                ))}
            </Flex>
        </Flex>
    );
}