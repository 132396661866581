import {
    toaster,
    Toaster,
    DialogRoot,
    DialogContent,
    DialogBody,
    Flex,
    Spinner,
    Text,
    Camera,
} from "src/components";
import {useEffect} from "react";
import {Layout, useLocation} from "src/items";
import * as signalR from "src/lib/signalR";
import {ItemDetail} from "src/views/logisticsPackages/itemdetail";
import {SoDetail} from "src/views/serviceOrder/detail";
import {Unauthorized} from "src/views/pages";
import {useRedux} from "src/redux/provider";
import Loading from "src/rts.loading";

const Core = () => {

    const location = useLocation();
    const {useStore, dispatch, actions} = useRedux();

    useEffect(() => {
        dispatch(actions.shared.fetch.RetrieveVendors());
        dispatch(actions.admin.fetch.GetConfig());
    }, [useStore.users.isAuth]);

    useEffect(() => {
        signalR.onChangeConnection((state, stateMsg, connectionId) => dispatch(actions.core.setConnection({
            connectionId: connectionId,
            state: signalR.hubConnect.state,
            message: stateMsg
        })));
    }, [useStore.core.signalr.state]);

    useEffect(() => {
        if (useStore.core.messages.length > 0) {
            let item = useStore.core.messages[0];
            toaster.create({
                title: item.title,
                description: item.message,
                type: item.serverity,
                duration: item.duration,
            });
            dispatch(actions.core.consumeMessage(item));
        }
    }, [useStore.core.messages]);

    useEffect(() => {
        if (location.pathname.indexOf("/price-proposal/") > -1 || location.pathname === "/mobile/scan")
            dispatch(actions.core.setIframe(true));
        else dispatch(actions.core.setIframe(false));
    }, [location.pathname]);
    const handleConnecting = () => {
        return (
            <DialogRoot open={!useStore.core.signalr.state} placement={"center"} size={"xl"}>
                <DialogContent>
                    <DialogBody>
                        <Flex direction={"column"} p={"100px"} gap={4} align={"center"}>
                            <Spinner/>
                            <Text>{useStore.core.signalr.message}</Text>
                        </Flex>
                    </DialogBody>
                </DialogContent>
            </DialogRoot>
        );
    };

    //regenerate token

    return (
        <>
            <Toaster/>
            {useStore.core.loading && <Loading text={useStore.core.loading_text ?? "Načítám data..."}/>}
            {!useStore.core.signalr.state && handleConnecting()}
            {!useStore.users.isAuth && <Unauthorized/>}
            {useStore.core.iframe && <Layout.Iframe/>}
            {useStore.core.signalr.state && useStore.users.isAuth &&
                <>
                    {!useStore.core.iframe && <Layout.MainLayout/>}
                    {
                        useStore.users.isAuth && (
                            <>
                                {useStore.logistics_packages.detail.state && <ItemDetail/>}
                                <SoDetail/>
                            </>
                        )
                    }
                    {useStore.core.camera.open && <Camera/>}
                </>
            }
        </>
    );
};

export default Core;
