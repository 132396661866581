import {Button, icon, MenuContent, MenuRadioItem, MenuRadioItemGroup, MenuRoot, MenuTrigger} from "src/components";
import React from "react";
import {useRedux} from "src/redux/provider";
import {useLocation, useNavigate} from "src/core";
import {useRts} from "src/provider";

export default function SoDetailOtherActions() {
    const {Redux, UseDrawer} = useRts();
    const {useStore, dispatch, actions, dispatchAsync} = useRedux();
    const data = useStore.service_order;
    const core = useStore.core.useDrawer;
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const queryState = searchParams.get("state");
    const id = searchParams.get("doc");

    const handleAction = (e: any) => {
        navigate(`/service-orders?doc=${id}&state=${e.value}`);
        UseDrawer((prev) => ({
            ...prev,
            sidebarItem: core.sidebarItems?.find((item) => item.value === e.value) ?? null,
            sidebarValue: e.value
        }))
        dispatch(actions.service_order.setState({
            ...data.actionState,
            otherAction: !data.actionState.otherAction
        }))
    };

    return <MenuRoot
        positioning={{placement: "top"}}
        open={data.actionState.otherAction}
        closeOnSelect={true}
        onClickOutside={() => dispatch(actions.service_order.setState({
            ...data.actionState,
            otherAction: !data.actionState.otherAction
        }))}
        onEscapeKeyDown={() => dispatch(actions.service_order.setState({
            ...data.actionState,
            otherAction: !data.actionState.otherAction
        }))}
    >
        <MenuTrigger>
            <Button
                variant="outline"
                size="sm"
                zIndex={1402}
                onClick={() => dispatch(actions.service_order.setState({
                    ...data.actionState,
                    otherAction: !data.actionState.otherAction
                }))}
            >
                <icon.LuEllipsisVertical/>
                Další akce
            </Button>
        </MenuTrigger>
        <MenuContent zIndex={9999}>
            <MenuRadioItemGroup
                value={queryState}
                onValueChange={handleAction}
            >
                <MenuRadioItem value="cn">Vystavit Cenovou
                    nabídku</MenuRadioItem>
            </MenuRadioItemGroup>
            <MenuRadioItemGroup
                value={queryState}
                onValueChange={handleAction}
            >
                <MenuRadioItem value="dis">Vystavit protokol o neshodě</MenuRadioItem>
            </MenuRadioItemGroup>
        </MenuContent>
    </MenuRoot>
};