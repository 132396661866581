import SidebarContent from "./SidebarContent";
import {
    useDisclosure,
    Button,
    DrawerRoot,
    icon,
    DrawerBackdrop,
    DrawerContent,
    DrawerCloseTrigger,
    DrawerBody,
    Box
} from "src/components";
import {useRedux} from "src/redux/provider";

function SidebarResponsive() {
    const {useStore, dispatch, actions} = useRedux();
    return (
        <Button variant={"plain"} size={"sm"} display={{sm: "flex", xl: "none"}} alignItems="center">
            <icon.FaBars onClick={() => dispatch(actions.core.toggleSidebar())}/>
            <DrawerRoot open={useStore.core.sidebar} placement={"start"}>
                <DrawerBackdrop/>
                <DrawerContent
                    w="250px"
                    maxW="250px"
                    ms={{
                        sm: "16px",
                    }}
                    my={{
                        sm: "16px",
                    }}
                    borderRadius="16px"
                >
                    <DrawerCloseTrigger
                        onClick={() => dispatch(actions.core.closeSidebar())}
                        _focus={{boxShadow: "none"}}
                        _hover={{boxShadow: "none"}}
                    />
                    <DrawerBody maxW="250px" px="1rem">
                        <Box maxW="100%" h="100vh">
                            <SidebarContent/>
                        </Box>
                    </DrawerBody>
                </DrawerContent>
            </DrawerRoot>
        </Button>
    );
}

export default SidebarResponsive;
