import {AdminItem} from "src/types/admin";
import {Avatar, Card, icon, Flex, Text} from "src/components";
import React from "react";
import {useRts} from "src/provider";

export default function AdminRenderItem(props: AdminItem) {
    const {UseDrawer} = useRts();
    return <Card key={props.key} isHoverable={true} variant={"subtle"} size={"sm"}
                 onClick={() => {
                     props.onBeforeLoad?.();
                     UseDrawer(props.dispatcher);
                 }} bodyElement={<Flex
        direction={"row"}
        gap={4}
        align={"center"}
        alignItems={"center"}
        justifyContent={"space-between"}
    >

        <Flex>
            <Avatar me={6} icon={<></>}>
                <props.icon/>
            </Avatar>
            <Flex direction={"column"}>
                <Text fontSize={"sm"} fontWeight={"bold"}>
                    {props.title}
                </Text>
                <Text fontSize={"xs"}>{props.description}</Text>
            </Flex>

        </Flex>
        <Flex direction={"column"}>
            <Text fontSize={"sm"}></Text>
            <Text fontSize={"xs"}>
            </Text>
        </Flex>
        <icon.FaArrowRight/>
    </Flex>}/>

}