import {Flex, icon, UseForm} from "src/components";
import {InputType} from "src/enums";
import {useEffect} from "src/core";
import {useRts} from "src/provider";
import {TemplateEditor} from "./template-editor";


export function Template() {
    const {Redux, Components, Core, UseDialog} = useRts();
    const {useStore, dispatch, actions, dispatchAsync} = Redux();
    const data = useStore.admin.Notification;
    useEffect(() => {
        if (data.template == "")
            dispatchAsync(actions.admin.fetch.GetTemplateDefinition())
    }, []);
    return (
        <Flex
            direction={"column"}
            gap={4}
            justifyContent={"space-between"}
        >
            <Flex w={"100%"} justifyContent={"end"}>
                <UseForm type={InputType.button}
                         width={"100%"}
                         children={<>
                             <icon.LuSave/>
                             Uložit</>}
                         btnVariant={"surface"}
                         onChange={() => dispatchAsync(actions.admin.fetch.AddOrUpdateTemplate(data.template))}/>
                <UseForm type={InputType.button}
                         width={"100%"}
                         children={<>
                             <icon.LuPen/>
                             Editovat</>}
                         btnVariant={"surface"}
                         onChange={() => UseDialog({
                             title: "Editace šablony",
                             bodyElement: <TemplateEditor/>,
                             size: "cover",
                             open: true,
                         })}/>
            </Flex>
            <div style={{
                borderRadius: "15px",
                marginTop: "10px"
            }} dangerouslySetInnerHTML={{__html: data.template}}/>

        </Flex>
    )
}