import PanelContainer from "../components/MainPanel/panelContainer";
import {Route, Routes} from "react-router-dom";
import {pages as Page} from "src/views";

export const Iframe = () => {
    return (
        <Routes>
            <Route path="mobile/scan" element={<PanelContainer><Page.CameraConnect/></PanelContainer>}/>
            <Route path="price-proposal/:id" element={<PanelContainer><Page.PriceProposal/></PanelContainer>}/>
        </Routes>
    );
};
