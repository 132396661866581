import {AbsoluteCenter, Flex} from "./components";
import {BlinkBlur} from "react-loading-indicators";

export default function Loading(props: { text?: string }) {
    const rgba = "rgba(0,0,0,.234)";
    console.log(props.text);
    let text = props.text === "" ? "Načítám data..." : props.text;
    return (
        <Flex
            height={"100vh"}
            zIndex={"2000"}
            backdropBlur={"xl"}
            bg={rgba}
            position={"fixed"}
            left={0}
            right={0}
            top={0}
            bottom={0}
        >
            <AbsoluteCenter>
                <Flex>
                    <BlinkBlur color="#9478b7" size="large" text={text} textColor="#fff"/>
                </Flex>
            </AbsoluteCenter>
        </Flex>
    );
}