import {Flex} from "src/components";
import {useRts} from "src/provider";
import {useNavigate} from "src/core";

export function Nextcloud() {

    const {UseDrawer, useState, useEffect} = useRts();
    const navigate = useNavigate();

    useEffect(() => {
        UseDrawer({
            open: true,
            size: "full",
            closable: true,
            onBeforeClose: () => navigate(-1),
            bodyElement: <Flex height={"91vh"} borderRadius={"15px"}>
                <iframe
                    src="https://central.britex.cz/"
                    style={{width: "100%", height: "100%", borderRadius: "15px"}}
                />
            </Flex>,
        })
    }, []);


    return <></>;
}