import {
    DialogBody,
    DialogCloseTrigger,
    DialogHeader,
    DialogRoot,
    DialogTitle,
    DialogContent,
    DialogFooter
} from "src/components/ui/dialog";
import * as icon from "src/components/icons";
import React, {useState} from "react";
import {Flex, Button} from "src/components";
import {UseDialogProps} from "src/types/core";

export function UseDialogHelper(props: UseDialogProps) {

    const {
        open,
        bodyElement,
        noPadding,
        closable,
        onClose,
        footerElement,
        headerElement,
        title,
        placement,
        size,
        maximized,
        backdrop,
        closeOnEscape,
        internalClose
    } = props;

    const handleClose = () => internalClose?.();
    const isPadding = noPadding ?? false;
    const isClosable = closable ?? true;
    const isEscape = closeOnEscape ?? true;
    const [actsize, setSize] = useState<"sm" | "md" | "lg" | "xl" | "xs" | "full" | "cover" | undefined>(undefined);
    return (
        <DialogRoot closeOnEscape={closeOnEscape} onEscapeKeyDown={() => isEscape ? handleClose() : {}} open={open}
                    placement={placement} size={actsize ?? size}>
            <DialogContent backdrop={backdrop ?? true}>
                {!isPadding && headerElement === undefined && <DialogHeader>
                    <Flex direction={"row"} gap={2} align={"center"} justifyContent={"space-between"}>
                        {title && <DialogTitle fontSize={"sm"}>
                            {title}
                        </DialogTitle>}
                        {maximized && (actsize ?? size) === "xl" &&
                            <Flex me={6} mt={-6}>
                                <Button size={"2xs"} toolTip={"Maximalizovat"}
                                        onClick={() => setSize("cover")} variant={"ghost"}>
                                    <icon.LuMaximize2/>
                                </Button>
                            </Flex>}
                        {maximized && (actsize ?? size) === "cover" &&
                            <Flex me={6} mt={-6}>
                                <Button size={"2xs"} toolTip={"Maximalizovat"}
                                        onClick={() => setSize("cover")} variant={"ghost"}>
                                    <icon.LuMaximize2/>
                                </Button>
                            </Flex>}
                    </Flex>
                    {isClosable &&
                        <DialogCloseTrigger onClick={handleClose}/>}

                </DialogHeader>}
                {!isPadding && (
                    <>
                        {bodyElement &&
                            <DialogBody>
                                {bodyElement}
                            </DialogBody>}
                        {footerElement &&
                            <DialogFooter>
                                {footerElement}
                            </DialogFooter>}
                    </>)}
                {isPadding && <>{bodyElement}</>}
            </DialogContent>
        </DialogRoot>
    );
}