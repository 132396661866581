import {CustomerItem} from "src/views/admin/components/PriceProposal/customer.item";
import React from "react";
import {useRts} from "src/provider";
import {CustomerMapItem} from "src/views/admin/components/PriceProposal/customer.map-item";
import {CustomerMapItemAction} from "src/views/admin/components/PriceProposal/customer.map-item-action";

export function CustomerMapPrice() {
    const {Redux, Components, UseDialog} = useRts();
    const {useStore, dispatch, actions, dispatchAsync} = Redux();
    const customers = useStore.admin.PriceProposalConfig.customers.lookup
        .map(x => ({value: x.value, label: x.label}))
        .sort((a, b) => a.label.localeCompare(b.label));
    const selected = useStore.admin.PriceProposalConfig.customers.selected;
    const data = useStore.admin.PriceProposalConfig.customers.map;
    const addorupdate = useStore.admin.PriceProposalConfig.customers.map_addorupdate;
    const {Button, Navigations} = Components;

    const handleAddUpdate = (e: number) => {
        let item = data.data.find(x => x.id === e);
        item ??= {
            id: 0,
            customer: selected.value,
            value: "",
            type: ["level"],
            op: ["="],
            map: ""
        }
        dispatch(actions.admin.setCustomerMapAddOrUpdate(item));
        UseDialog({
            open: true,
            title: "Přidat nový maping",
            size: "lg",
            noPadding: false,
            bodyElement: <CustomerMapItem/>,
            footerElement: <CustomerMapItemAction/>,
            closable: true,
        })
    }

    return (<>
        <Button size={"sm"} variant={"surface"}
                disabled={customers.length === 0 || selected.value === ""}
                onClick={() => {
                    handleAddUpdate(0);
                }}>
            Přidat nový maping
        </Button>

        <Navigations
            mb={{mb: 2}}
            type=""
            data={data}
            renderComponent={CustomerItem}
        />
    </>)
}