import {icon} from "src/components";
import Core from "src/rts.core";
import {Group} from "./types/users";
import {
    Admin,
    LogisticPackage,
    ServiceRequest,
    ServiceOrders,
    CameraConnect,
    Grafana,
    Mattermost,
    Nextcloud,
    Gitlab
} from "./views/pages";

export const RtsRoutes = {
    Core,
}

function AddRoute(route: any, routes: Array<any>) {
    let exists = routes.some((x) => x.path === route.path);
    if (!exists)
        routes.push(route);
    return routes;
}

function EnhanceRoutes(routes: Array<any>) {
    const hasDefaultRoute = routes.some((x) => x.path === "/");
    if (!hasDefaultRoute) {
        routes.push({
            path: "/",
            name: "Vytvořit žádost",
            icon: <icon.FaFileContract color="inherit"/>,
            component: <ServiceRequest/>,
            layout: "",
            sidebar: false,
        });
    }
    return routes;
}

function logisticsRoutes(actualRoutes: Array<any>, group_list: Group[]) {
    let r = actualRoutes;
    const some_group = group_list.some((x) => x.name === "RTS Logistika");
    if (some_group) {
        r = AddRoute({
            path: "/packages",
            name: "Balíky",
            icon: <icon.FaBoxOpen color="inherit"/>,
            component: <LogisticPackage/>,
            layout: "",
            sidebar: true,
        }, r);
    }
    return r
};

function adminRoutes(actualRoutes: Array<any>, group_list: Group[]) {
    let r = actualRoutes;

    const some_group = group_list.some((x) => x.name === "RTS Admin");

    if (some_group) {
        r = AddRoute({
            path: "/admin",
            name: "Administrace",
            icon: <icon.LuServerCog color="inherit"/>,
            component: <Admin/>,
            layout: "",
            sidebar: true,
        }, r);
    }
    return r
}

function secretRoutes(group_list: Group[]) {
    let r = dashRoutes;
    const some_group = group_list.some((x) => x.name === "BRITEX-CZs.r.o");
    //budeme přidávat pouze pokud neexistuje

    if (some_group) {
        r = [];
        r = AddRoute({
            path: "/camera",
            name: "Kamera",
            icon: <icon.LuCamera color="inherit"/>,
            component: <CameraConnect/>,
            layout: "",
            sidebar: false,
        }, r);
        r = AddRoute({
            path: "/",
            name: "Servisní zakázky",
            icon: <icon.FaHome color="inherit"/>,
            component: <ServiceOrders/>,
            layout: "",
            sidebar: false,
        }, r);
        r = AddRoute({
            path: "/service-orders",
            name: "Servisní zakázky",
            icon: <icon.FaFileContract color="inherit"/>,
            component: <ServiceOrders/>,
            layout: "",
            sidebar: true,
        }, r);
        r = logisticsRoutes(r, group_list);
        r = AddRoute({
            path: "/grafana",
            name: "Grafana",
            icon: <icon.SiGrafana color="inherit"/>,
            component: <Grafana/>,
            layout: "",
            sidebar: true,
        }, r);
        r = AddRoute({
            path: "/central",
            name: "Centrál",
            icon: <icon.SiNextcloud color="inherit"/>,
            component: <Nextcloud/>,
            layout: "",
            sidebar: true,
        }, r);
        r = AddRoute({
            path: "/chat",
            name: "Mattermost",
            icon: <icon.SiMattermost color="inherit"/>,
            component: <Mattermost/>,
            layout: "",
            sidebar: true,
        }, r);

        r = adminRoutes(r, group_list);
        r = AddRoute({
            path: "/gitlab",
            name: "Gitlab",
            icon: <icon.SiGitlab color="inherit"/>,
            component: <Gitlab/>,
            layout: "",
            sidebar: true,
        }, r);

    }
    return EnhanceRoutes(r);
}

const dashRoutes: Array<any> = [
    {
        path: "/create/service/request",
        name: "Vytvořit žádost",
        icon: <icon.FaFileContract color="inherit"/>,
        component: <ServiceRequest/>,
        layout: "",
        sidebar: true,
    },
    {
        path: "/display/service/request",
        name: "Správa žádostí",
        icon: <icon.FaRegEdit color="inherit"/>,
        component: <ServiceRequest/>,
        layout: "",
        sidebar: true,
        disabled: true,
    },
    {
        path: "/camera",
        name: "Kamera",
        icon: <icon.LuCamera color="inherit"/>,
        component: <CameraConnect/>,
        layout: "",
        sidebar: false,
    }
];

export default secretRoutes;
