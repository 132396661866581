import React from "react";
import {CustomerSearch} from "./customer.search";
import {useRts} from "src/provider";
import {SegmentedControl} from "src/components";
import {HStack} from "@chakra-ui/react";
import {CustomerMapPrice} from "src/views/admin/components/PriceProposal/customer.map-price";
import {CustomerRewriteEmail} from "src/views/admin/components/PriceProposal/customer.rewrite-email";
import {CustomerOther} from "src/views/admin/components/PriceProposal/customer.other";


export function Customer() {
    const {UseDialog, Redux, Components} = useRts();
    const {useStore, dispatch, actions} = Redux();
    const {Flex, Button, icon, Text} = Components;
    const [separator, setSeparator] = React.useState<string>("if_else");
    const selected = useStore.admin.PriceProposalConfig.customers.selected;
    return (
        <>
            <Flex direction={"column"} gap={2}>
                <Button
                    onClick={() => UseDialog({
                        open: true,
                        title: "Vyber zákazníka",
                        size: "xl",
                        noPadding: true,
                        backdrop: true,
                        closeOnEscape: true,
                        bodyElement: <CustomerSearch/>,
                        closable: false,
                    })}
                    variant={"surface"}
                    toolTip={"Vyber zákazníka"}
                >
                    <Flex justifyContent={"space-between"} alignContent={"center"} w={"100%"}>
                        <icon.LuSearch/>
                        <Text>{selected.value === "" ? "Vyber zákazníka" : selected.label}</Text>
                        {selected.value === "" ? <icon.LuArrowDown/> : <icon.LuX style={{
                            cursor: "pointer",
                        }} onClick={(e) => {
                            e.stopPropagation();
                            dispatch(actions.admin.setPriceProposalCustomerSelected({
                                value: "",
                                label: ""
                            }))
                        }}/>}
                    </Flex>
                </Button>
                {selected.value !== "" && (<>
                    <SegmentedControl
                        mt={4}
                        defaultValue={separator}
                        onChange={(e: any) => setSeparator(e.target.value)}
                        items={[
                            {
                                value: "if_else",
                                label: (
                                    <HStack borderRadius={"15px"}>
                                        <icon.LuTable/>
                                        Podmínky
                                    </HStack>
                                ),
                            },
                            {
                                value: "map_price",
                                label: (
                                    <HStack>
                                        <icon.LuGrid2X2/>
                                        Mapování cen
                                    </HStack>
                                ),
                            },
                            {
                                value: "other",
                                label: (
                                    <HStack>
                                        <icon.LuServerCog/>
                                        Ostatní nastavení
                                    </HStack>
                                ),
                            }
                        ]}
                    />
                    {separator === "if_else" && <CustomerRewriteEmail/>}
                    {separator === "map_price" && <CustomerMapPrice/>}
                    {separator === "other" && <CustomerOther/>}
                </>)}
            </Flex>
        </>
    );
}