import {useNavigate} from "src/core";
import {Avatar, Card, Checkbox, icon, Flex, Tag, Text} from "src/components";
import {CardHover} from "src/theme";
import {ResponseOverview} from "src/types/service_order/overview";

export function SoItem(props: { item: ResponseOverview }) {
    const {item} = props;
    const navigate = useNavigate();
    const tatPallete = () => {
        const is_danger = item.tat > 19;
        const is_warning = item.tat >= 10 && item.tat <= 19;
        const is_success = item.tat < 10;

        if (is_danger) {
            return "red";
        } else if (is_warning) {
            return "orange";
        } else if (is_success) {
            return "green";
        }
    }
    return (
        <Card isHoverable={true} variant={"subtle"} size={"sm"}
              onClick={() => navigate(`?doc=${item.id}`)} bodyElement={<Flex
            direction={"row"}
            gap={4}
            align={"center"}
            alignItems={"center"}
            justifyContent={"space-between"}
        >
            <Flex gap={4}>
                <Checkbox/>
                <Avatar src={""} name={item.vendorName}></Avatar>
                <Flex direction={"column"} minW={"120px"}>
                    <Tag>{item.statusText}</Tag>
                    <Tag fontSize={"xs"} colorPalette={tatPallete()}>TAT: {item.tat} / den</Tag>
                </Flex>

                <Flex direction={"column"} minW={"120px"}>
                    <Text fontSize={"sm"} fontWeight={"bold"}>
                        {item.id}
                    </Text>
                    <Text fontSize={"xs"}>{item.managedBy}</Text>
                </Flex>
                <Flex direction={"column"} minW={"180px"}>
                    <Text fontSize={"sm"} fontWeight={"bold"}>
                        {item.vendorName}
                    </Text>
                    <Text fontSize={"xs"}>{item.deviceName}</Text>
                </Flex>
                <Flex direction={"column"} w={"180px"}>
                    <Text fontSize={"sm"}>{item.repairTypeText}</Text>
                    <Text fontSize={"xs"}>
                        {new Date().toLocaleDateString()}
                    </Text>
                </Flex>
                <Flex direction={"row"} gap={4}>
                    <Tag colorPalette={"red"}>LTP</Tag>
                    <Tag colorPalette={"red"}>PLO</Tag>
                </Flex>
            </Flex>


            <icon.FaArrowRight/>
        </Flex>}/>
    );
}
