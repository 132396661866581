import {Flex} from "src/components";
import {useEffect} from "react";
import {useRts} from "src/provider";
import {useNavigate} from "src/core";

export function Grafana() {
    const {UseDrawer, useState, useEffect} = useRts();
    const navigate = useNavigate();
    useEffect(() => {
        UseDrawer({
            open: true,
            size: "full",
            closable: true,
            onBeforeClose: () => navigate(-1),
            bodyElement: <Flex height={"91vh"} borderRadius={"15px"}>
                <iframe
                    src="https://grafana.britex.cz/login/generic_oauth"
                    style={{width: "100%", height: "100%", borderRadius: "15px"}}
                />
            </Flex>,
        })
    }, []);

    return <></>
}